<template>
  <div style="margin-top: 20px;">
    <nav
      :style="{width: this.$language === 'cn' ? '210px' : '220px'}"
      class="navbar-default"
      style="width: 220px;"
    >
      <ol class="nav isreal_navleft">
        <!-- 合作信息 -->
        <li
          :style="{ paddingLeft: this.$language === 'cn' ? '30px' : '5px' }"
          class="isreal_navleft1"
        >
          {{ $t('m.navbar.cooperate_info') }}
        </li>
        <!-- 中东欧合作需求 -->
        <li class="plan_whline" @click="handleMenuClick('1')">
          <a
            v-if="this.$language === 'cn'"
            :class="{'font-corss': activeIndex === '1', 'font-cors': activeIndex === '1'}"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            {{ $t('m.navbar.ceec_cooperate_text') }}
          </a>
          <a
            v-else
            :class="{'font-corss': activeIndex === '1', 'font-cors': activeIndex === '1'}"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            <span class="child-menu-en">
              {{ $t('m.navbar.ceec_cooperate_text') }}
            </span>
          </a>
        </li>
        <!-- 中方合作需求 -->
        <li class="plan_whline" @click="handleMenuClick('2')">
          <a
            v-if="this.$language === 'cn'"
            :class="{'font-corss': activeIndex === '2', 'font-cors': activeIndex === '2'}"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            {{ $t('m.navbar.china_cooperate_text') }}
          </a>
          <a
            v-else
            :class="{'font-corss': activeIndex === '2', 'font-cors': activeIndex === '2'}"
            style="font-size: 12px;"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            <span class="child-menu-en">
              {{ $t('m.navbar.china_cooperate_text') }}
            </span>
          </a>
        </li>
        <!-- 中东欧技术供给 -->
        <li v-if="false" class="plan_whline" @click="handleMenuClick('5')">
          <a
            v-if="this.$language === 'cn'"
            :class="{'font-corss': activeIndex === '5', 'font-cors': activeIndex === '5'}"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            {{ $t('m.navbar.ceec_tech_supply_text') }}
          </a>
          <a
            v-else
            :class="{'font-corss': activeIndex === '5', 'font-cors': activeIndex === '5'}"
            style="font-size: 12px;"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            <span class="child-menu-en">
              {{ $t('m.navbar.ceec_tech_supply_text') }}
            </span>
          </a>
        </li>
        <!-- 中方技术供给 -->
        <li v-if="false" class="plan_whline" @click="handleMenuClick('6')">
          <a
            v-if="this.$language === 'cn'"
            :class="{'font-corss': activeIndex === '6', 'font-cors': activeIndex === '6'}"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            {{ $t('m.navbar.china_tech_supply_text') }}
          </a>
          <a
            v-else
            :class="{'font-corss': activeIndex === '6', 'font-cors': activeIndex === '6'}"
            style="font-size: 12px;"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            <span class="child-menu-en">
              {{ $t('m.navbar.china_tech_supply_text') }}
            </span>
          </a>
        </li>
        <!-- 项目合作需求 -->
        <li class="plan_whline" @click="handleMenuClick('3')">
          <a
            v-if="this.$language === 'cn'"
            :class="{'font-corss': activeIndex === '3', 'font-cors': activeIndex === '3'}"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            {{ $t('m.navbar.project_cooperate_text') }}
          </a>
          <a
            v-else
            :class="{'font-corss': activeIndex === '3', 'font-cors': activeIndex === '3'}"
            style="font-size: 12px;"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            <span class="child-menu-en">
              {{ $t('m.navbar.project_cooperate_text') }}
            </span>
          </a>
        </li>
        <!-- 创新机构介绍 -->
        <li v-if="false" class="plan_whline" @click="handleMenuClick('4')">
          <a
            v-if="this.$language === 'cn'"
            :class="{'font-corss': activeIndex === '4', 'font-cors': activeIndex === '4'}"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            {{ $t('m.navbar.innovation_institution_text') }}
          </a>
          <a
            v-else
            :class="{'font-corss': activeIndex === '4', 'font-cors': activeIndex === '4'}"
            style="font-size: 12px;"
          >
            <img style="margin-right: 10px;" src="../../../assets/images/aboutss_03.png">
            <span class="child-menu-en">
              {{ $t('m.navbar.innovation_institution_text') }}
            </span>
          </a>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
//
export default {
  props: {
    activeIndex: {
      type: String,
      default: '1'
    }
  },
  methods: {
    handleMenuClick(val) {
      if (val === '1') {
        // 中东欧合作需求
        this.$router.push({ path: '/cooperate' })
      } else if (val === '2') {
        // 中方合作需求
        this.$router.push({ path: '/cooperate/china' })
      } else if (val === '3') {
        // 项目合作需求
        this.$router.push({ path: '/cooperate/project' })
      } else if (val === '4') {
        // 创新机构介绍
        this.$router.push({ path: '/cooperate/innovation'})
      } else if (val === '5') {
        // 中东欧技术供给
        this.$router.push({ path: '/cooperate/tech/supply/ceec'})
      } else if (val === '6') {
        // 中方技术供给
        this.$router.push({ path: '/cooperate/tech/supply/china'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
    .nav {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }
  }
  .isreal_navleft1 {
    height: 40px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 40px;
    background-color: #0077cc !important;
  }
  .isreal_navleft {
    li {
      cursor: pointer;
      background-color: #eaeaea;
      a {
        display: block;
        position: relative;
        padding: 10px 15px;
        color: #666666;
        line-height: 16px;
        img {
          vertical-align: middle;
        }
      }
    }
    li:hover {
      background-color: #eeeeee;
    }
    .plan_whline {
      border-bottom: 1px #FFFFFF solid;
    }
  }
  .font-corss {
    border-left: 1px #253d97 solid;
  }
  .font-cors {
    color: #0078cb !important;
  }
  .child-menu-en {
    width: calc(100% - 1px);
    font-size: 12px !important;
    zoom: 0.87;
  }
</style>
