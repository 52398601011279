<template>
  <!-- 行业筛选框 -->
  <div class="guojia_con">
    <!-- 国家 -->
    <span>{{ $t('m.hezuo.country_title') }}</span>
    <!-- 全部 -->
    <a class="a_all">
      <div
        :style="{
          backgroundColor: country ? '' : '#465364',
          color: country ? '' : '#ffffff',
          borderRadius: country ? '' : '5px',
          fontWeight: this.$language === 'cn' ? 'none' : 'bold'
        }"
        class="select-all"
        @click="handleAllClick"
      >
        {{ $t('m.hezuo.all_text') }}
      </div>
    </a>
    <!-- -->
    <div class="huoj_content">
      <a
        v-for="item in countryOptions"
        :key="item.value"
        :class="{ select_item : selectIndex1 === item.value}"
        @click="handleItemClick(item)"
      >{{ item.label }}</a>
    </div>
  </div>
</template>

<script>
//
import { countryList } from '@/api/cceec/constant'
export default {
  props: {
    country: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      hoverIndex1: 1,
      selectIndex1: null,
      countryOptions: []
    }
  },
  created() {
    this.getCountryList()
  },
  methods: {
    //  全部 点击
    handleAllClick() {
      // this.country = null
      this.selectIndex1 = null
      this.$emit('handleCountryChange', null)
    },
    //  一级菜单 点击
    handleItemClick(item) {
      this.selectIndex1 = item.value
      this.$emit('handleCountryChange', item.value)
    },
    //  国家
    getCountryList() {
      countryList().then(res => {
        console.log(res)
        const countryOptions = []
        const list = res.data
        list.forEach(item => {
          if (item.dkey !== '1') {
            const obj = {
              value: item.dkey,
              label: this.$language === 'cn' ? item.dvalue : item.english
            }
            countryOptions.push(obj)
          }
        })
        this.countryOptions = countryOptions
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .guojia_con {
    display: flex;
    width: 950px;
    height: auto;
    word-break: break-all;
    white-space: normal;
    border-radius: 5px;
    border: 1px #ccc solid;
    margin-top: 10px;
    padding-top: 5px;
    span {
      margin-left: 5px;
      margin-right: 10px;
      font-size: 15px;
      font-weight: bold;
      color: #333;
      vertical-align: top;
    }
    .a_all {
      vertical-align: top;
      display: inline-block;
      font-size: 15px;
      padding-right: 20px;
      color: #000;
      cursor: pointer;
      padding-bottom: 10px;
    }
  }
  .select-alls {
    font-size: 15px;
    color: #333;
  }
  .huoj_content {
    /*display: inline-block;*/
    /*padding-top: 7px;*/
    display: flex;
    flex-wrap: wrap;
    width: 810px;
    height: auto;
    /*word-break: break-all;*/
    /*white-space: normal;*/
    font-size: 13px;
    /*border: 1px solid yellow;*/
    a {
      padding: 5px 20px 5px 0;
      color: #000;
      cursor: pointer;
      line-height: 24px;
      /*border: 1px solid red;*/
    }
    a:hover {
      color: #0077cc;
      text-decoration: underline;
    }
  }
  .select_item {
    color: #0077cc !important;
    text-decoration: underline;
  }
</style>
