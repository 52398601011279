<template>
  <!-- 行业筛选框 -->
  <div class="hyfl_tottle">
    <!-- 行业 -->
    <div class="select-tit">行业</div>
    <!-- 全部 -->
    <a>
      <!-- style="background-color: #465364; color: #ffffff; border-radius: 5px;" -->
      <div
        :style="{
          backgroundColor: industry ? '' : '#465364',
          color: industry ? '' : '#ffffff',
          borderRadius: industry ? '' : '5px'
        }"
        class="select-all"
        @click="handleAllClick"
      >
        全部
      </div>
    </a>
    <!-- -->
    <div class="select-contet">
      <div
        v-for="item in industryOptions" :key="item.value">
        <a
          class="st_1"
          @mousemove="handleMousemove(item)"
          @mouseout="handleMouseout"
        >
          <span
            :class="[{'first_select': selectIndex1 === item.value}]"
            @click="handleItemClick(item)"
          >
            {{ item.label }}
          </span>
          <img
            v-if="hoverIndex1 === item.value"
            src="../../../assets/images/sanjiao_03.png"
            @mousemove="handleMousemove(item)"
            @mouseout="handleMouseout"
          >
        </a>
        <!-- 二级 -->
        <div
          v-if="hoverIndex1 === item.value"
          class="l2-list"
          @mousemove="handleMousemove(item)"
          @mouseout="handleMouseout"
        >
          <a
            v-for="it in item.childrens"
            :key="it.value"
            :class="[{'select_item': selectIndex2 === it.value}]"
            @click="handleSecondItemClick(item, it)"
          >
            {{ it.label }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//
import { industryList } from '@/api/cceec/constant'

export default {
  props: {
    // industry: {
    //   type: Number,
    //   default: null
    // }
  },
  data() {
    return {
      industry: false,
      hoverIndex1: 1,
      selectIndex1: null,
      selectIndex2: null,
      industryOptions: []
    }
  },
  created() {
    this.getIndustryList()
  },
  methods: {
    //  全部 点击
    handleAllClick() {
      this.industry = false
      this.selectIndex1 = null
      this.selectIndex2 = null
      this.hoverIndex1 = 1
      this.$emit('handleAllIndustry')
    },
    //  一级菜单 点击
    handleItemClick(item) {
      this.industry = true
      this.selectIndex1 = item.value
      this.selectIndex2 = null
      this.$emit('handleIndustryFirstChange', item.value)
    },
    //  二级菜单 点击
    handleSecondItemClick(first, second) {
      this.industry = true
      this.selectIndex1 = first.value
      this.selectIndex2 = second.value
      this.$emit('handleIndustrySecondChange', first.value, second.value)
    },
    //  鼠标移入
    handleMousemove(item) {
      this.hoverIndex1 = item.value
    },
    //  鼠标移出
    handleMouseout() {
      if (this.selectIndex1) {
        this.hoverIndex1 = this.selectIndex1
      } else {
        this.hoverIndex1 = 1
      }
    },
    getIndustryList() {
      const data = {
        level: 1
      }
      industryList(data).then(res => {
        // console.log(res)
        const list = res.data
        const industryOptions = []
        list.forEach(item => {
          const it = {
            value: item.id,
            label: this.$language === 'cn' ? item.classifyName : item.english,
            childrens: []
          }
          const data = {
            level: 2,
            fatherId: it.value
          }
          industryList(data).then(res => {
            // console.log(res)
            const list = res.data
            const secondIndustrys = []
            list.forEach(item => {
              const it2 = {
                value: item.id,
                label: this.$language === 'cn' ? item.classifyName : item.english
              }
              secondIndustrys.push(it2)
            })
            it.childrens = secondIndustrys
          })
          industryOptions.push(it)
        })
        this.industryOptions = industryOptions
        // console.log(this.industryOptions)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .hyfl_tottle {
    margin-top: 10px;
    padding-top: 5px;
    width: 950px;
    height: 120px;
    position: relative;
    word-break: break-all;
    white-space: normal;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    /* title */
    .select-tit {
      float: left;
      margin-left: 5px;
      margin-right: 15px;
      color: #333;
      font-size: 15px;
      font-weight: bold;
    }
    /* 全部 */
    .select-all {
      float: left;
      margin-right: 15px;
      color: #333;
      font-size: 15px;
      cursor: pointer;
    }
    /**/
    .select-contet {
      /*padding-top: 6px;*/
      /*border: 1px solid red;*/
      a {
        display: block;
        float: left;
        /*padding-right: 20px;*/
        /*margin-right: 20px;*/
        color: #000;
        font-size: 13px;
        text-decoration: none;
      }
      .st_1 {
        padding-right: 20px;
        line-height: 15px;
        text-align: center;
        cursor: pointer;
        span {
          display: block;
          padding: 5px 0;
          text-align: center;
        }
        /*span:hover {*/
          /*padding: 5px 10px;*/
          /*color: #ffffff !important;*/
          /*border-radius: 5px;*/
          /*background-color: #465364;*/
        /*}*/
        img {
          position: absolute;
          top: 30px;
        }
      }
      .st_1:hover {
        padding-bottom: 10px;
        span {
          padding: 5px 10px;
          color: #ffffff !important;
          border-radius: 5px;
          background-color: #465364;
        }
      }
      .l2-list {
        position: absolute;
        top: 36px;
        left: 100px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        width: 820px;
        border: 1px #ccc solid;
        border-radius: 5px;
        word-break: break-all;
        word-spacing: normal;
        white-space: normal;
        a {
          margin: 0;
          line-height: 25px;
          display: inline-block;
          text-align: center;
          padding-left: 10px;
          padding-right: 10px;
          cursor: pointer;
        }
        a:hover {
          color: #0077cc;
          text-decoration: underline;
        }
      }
    }
  }
  .first_select {
    color: #0077cc;
    text-decoration: underline;
  }
  .select_item {
    color: #ffffff !important;
    border-radius: 5px;
    background-color: #465364;
  }
</style>
